import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import SocialLinks from "../components/sociallinks";
import PortfolioList from "../components/list-portfolio";
import ServiceList from "../components/service-list";
import "../style/wall.less";

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winHeight: "100vh"
        };
    }



    componentWillUnmount() {
        window.removeEventListener("resize", this.setWindowHeight);
    }

    componentDidMount() {
        this.setWindowHeight();

        window.addEventListener("resize", this.setWindowHeight);

        
    }

    setWindowHeight = () => {
        this.setState({
            winHeight: window.innerHeight
        });
    }

    render() {
        return (
            <Layout placeholder={false}>
                <SEO
                    title={this.props.data.site.siteMetadata.title}
                />
                <div
                    className="wall"
                    style={{ height: this.state.winHeight + "px" }}
                >
                    <div className="intro container">
                        <h1 className="text-secondary">{this.props.data.site.siteMetadata.logoTitle}</h1>
                        <p className="tag-line text-secondary">
                            {this.props.data.site.siteMetadata.introTag}
                        </p>
                        <p className="caption text-tertiary">
                            {this.props.data.site.siteMetadata.description}
                        </p>
                        <a href="#portfolio" className="btn" id="whatwedid">
                            NELER YAPTIK
                        </a>
                        <div class="scroll-downs">
                        <div class="mousey">
                            <div class="scroller"></div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="social-buttons">
                        <SocialLinks />
                    </div>
                </div>
                <ServiceList />
                <PortfolioList />
            </Layout>
        );
    }
}

export default IndexPage;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                logoTitle
                capitalizeTitleOnHome
                introTag
                description
                siteUrl
                social {
                    name
                    url
                    icon
                }
            }
        }
    }
`;