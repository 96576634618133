import React from "react";
import {ServiceWeb,ServiceSEO,ServiceMaintenance,ServiceAds} from "./icons"
import "../style/list-service.less"

export default function ServiceList() {
    return (
        <section id="service" className="container" >
            <div className="row">
                <h2 className="section-title">SERVİSLERİMİZ</h2>
            </div>
            <div className="row">
                <div className="item col s12 m6">
                    <ServiceWeb />
                    <h3 className="text-primary">
                        Web Tasarım
                    </h3>
                    <p className="text-tertiary">İhtiyacınıza uygun kullanıcı dostu ve gelişime açık, kolay yönetilebilir web siteleri ve uygulamalar</p>
                </div>
                <div className="item col s12 m6">
                    <ServiceSEO />
                    <h3 className="text-primary">
                        SEO & UX
                    </h3>
                    <p className="text-tertiary">Arama motorları ve hızlı kullanıcı deneyimi için en optimal altyapı ve içerik düzenlemesi</p>
                </div>
                

            </div>
            <div className="row">
                <div className="item col s12 m6">
                    <ServiceMaintenance />
                    <h3 className="text-primary">
                        Web Sitesi Yönetimi
                    </h3>
                    <p className="text-tertiary">Güncelemelerden güvenlik açıklarına, içerikten SEO ayarlarına kadar tüm site gereksinimleriniz için

</p>
                </div>
                <div className="item col s12 m6">
                    <ServiceAds />
                    <h3 className="text-primary">
                        Reklamlar
                    </h3>
                    <p className="text-tertiary">Tüm dijital platformlardaki hedef kitlenize online reklamlar ile ulaşın</p>
                </div>
                

            </div>

        </section>
    );
}