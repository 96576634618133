import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PortfolioItems from "./items-portfolio";

export default function() {
    const query = useStaticQuery(graphql`
        query portfolioList {
            allMarkdownRemark(
                filter: {frontmatter: {featured: {eq: true}}, fileAbsolutePath: {regex: "/proje/"}}
                limit: 6
                sort: { fields: [frontmatter___date], order: DESC }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            description
                            image {
                                publicURL
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                        srcSet
                                        ...GatsbyImageSharpFluid
                                    }
                                    id
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <section id="portfolio" className="container">
            <div>
                <h2 className="section-title">BİTEN İŞLERİMİZDEN</h2>
            </div>
            <PortfolioItems data={query} />
            <div className="row flex">
                <Link to="/neler-yaptik" className="btn">{`TÜM PROJELERİ GÖRÜNTÜLE`}</Link>
            </div>
        </section>
    );
}
